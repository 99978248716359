<template>
    <div class="filter-item__popover" :class="$style.sectionBase">
        <div
            class="filter-item__title"
            :class="['placeholder', when.visiblePopver && 'focus', $style.section]"
            @click="togglePopver(when.visiblePopver)"
        >
            <div
                :class="$style.valueTitle"
                class="valueTitle"
                v-html="when.htmlValue"
                v-if="when.htmlValue"
                :title="when.value"
            ></div>
            <span :class="$style.valuePlaceholder" v-else>{{ $t('common.multiple_explain') }}</span>
            <span class="filter-item__title-suffix">
                <i class="el-icon-arrow-down"></i>
            </span>
            <div :class="$style.valueCount" v-if="when.visibleCount > 0">
                <p :class="$style.stateNumber">+ {{ when.visibleCount }}</p>
            </div>
        </div>
        <transition name="el-fade-in-linear" v-if="when.visiblePopver">
            <div class="el-popover el-popper filter-item__popover-container">
                <pt-multi-select
                    v-pt-clickoutside="handleClickOutside"
                    :tag-placeholder="tagPlaceholder"
                    :placeholder="placeholder"
                    value-key="code"
                    label-key="name"
                    :value="value"
                    :options="options"
                    :allow-create="allowCreate"
                    :trim-space="trimSpace"
                    :allow-empty="allowEmpty"
                    :blur-create="blurCreate"
                    :expandAfterInit="true"
                    @change="handleWhenValueChange"
                ></pt-multi-select>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'multipleItemWithPopover',
    props: {
        data: {
            type: Object,
            default: {}
        },
        tagPlaceholder: String,
        placeholder: {
            type: String,
            default: ''
        },
        value: Array,
        options: Array,
        allowCreate: Boolean,
        trimSpace: Boolean,
        allowEmpty: Boolean,
        blurCreate: Boolean
    },
    data() {
        return {
            when: this.data
        };
    },
    methods: {
        togglePopver(status) {
            this.$emit('toggle', !status);
        },
        handleWhenValueChange(val) {
            this.$emit('change', { when: this.when, val });
        },
        handleClickOutside() {
            this.$emit('toggle', false);
        }
    }
};
</script>
<style lang="scss" module>
@import '@/styles/import.scss';
.sectionBase {
    position: relative;
    :global {
        .el-popover {
            position: relative;
        }
    }

    .section {
        width: auto;
        padding: 0 50px 0 15px;
    }

    :global {
        .filter-item__popover-container {
            margin: 0;
            top: -46px;
        }
        .filter-item__title-suffix {
            right: 6px;
        }

        .conditionKeyWord {
            box-sizing: border-box;
            border-color: transparent;
            margin: 0 2px;
            background-color: #f2f7f2;
            height: 24px;
            padding: 0 8px;
            line-height: 24px;
            white-space: nowrap;
            border-radius: 4px;
            font-size: 13px;
            color: #091e42;
            max-width: 100%;
            display: block;
        }
        .ellipsisWord {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .conditionSepreateWord {
            margin: 0 2px;
            font-size: 13px;
            color: #8993a4;
        }
    }
}
.valueTitle {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    align-items: center;
    height: 30px;
}
.valuePlaceholder {
    display: inline-block;
    line-height: 30px;
    font-size: 13px;
    color: #8993a4;
}
.valueCount {
    position: absolute;
    right: 22px;
    top: 1px;
    display: flex;
    align-items: center;
    .stateNumber {
        line-height: 28px;
    }
}
</style>
